import apiService from "../config/api";

export const getLogoAndSectionService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.website_details`);

export const getCarouselService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.carousel_images`);

export const getPrizeService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.prizes_won`);

export const getSponsorshipLogoService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.sponsorship_logo`);

export const getOrganizerService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.organizer`);

export const getSection1Service = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.section1`);

export const getSection2Service = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.section_2`);

export const getOrgSettingsService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.org_settings`);

export const pastEventListService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.past_event_list`);

// Participants
export const getBranchDetailService = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.branch_details`);

// Winners corner
export const getWinners = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.winners`);
export const getaWinners = async () =>
  await apiService.get(`/jewelery_coupon_system.website_api.jeweller_lucky_draw_winners`);

// Lucky Draw
export const getLuckyDraw = async (payload) =>
  await apiService.post(
    `/jewelery_coupon_system.api.coupon_api.get_winner_coupons`,
    payload
  );

// past event page content
export const pastEventDetailService = async (festival) =>
  await apiService.get(
    `/jewelery_coupon_system.website_api.past_event_details?festival=${festival}`
  );
export const pastEventCarouselImgsService = async (festival) =>
  await apiService.get(
    `/jewelery_coupon_system.website_api.past_carousel_images_images?festival=${festival}`
  );
export const pastEventListImgsService = async (festival) =>
  await apiService.get(
    `/jewelery_coupon_system.website_api.past_event_images?festival=${festival}`
  );
