const appConfig = {
  app: {
    name: "Bengaluru Gold Festival",
    version: "1.0.0",
  },
  baseUrl: "https://jewellers.goldfestival.in/api/method",
  baseUrlImg: "https://jewellers.goldfestival.in",
  token: "token cf2a4084ac49739:7598d9f30405aa8",
};

export default appConfig;
