import axios from "axios";
import appConfig from "./app.config";

const apiService = axios.create({
  baseURL: appConfig.baseUrl,
});

apiService.interceptors.request.use((config) => {
  config.headers.Authorization = appConfig.token;
  return config;
});

export default apiService;
