/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Grid, Drawer, Space, message } from "antd";
import { Link } from "react-router-dom";
import {
    MenuUnfoldOutlined
} from '@ant-design/icons';
import { primaryColor } from "../../app/config/theme";
import { useEffect, useState } from "react";
// import logo from './bgf_logo.png'
import { useDispatch, useSelector } from "react-redux";
import { logoAndSectionReduxService } from "../../app/redux/slices/logoAndSectionReducer";
import appConfig from "../../app/config/app.config";
const { useBreakpoint } = Grid;


const TopNavBar = ({ menu, showMenu = true }) => {
    const screens = useBreakpoint();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const { data, error } = useSelector(state => state.logoAndSectionReducer);

    useEffect(() => {
        dispatch(logoAndSectionReduxService());
    }, [])

    useEffect(() => {
        if (error) message.error(error.toString());
    }, [error])



    const Menus = ({ cls = false }) => (
        <ul className={cls && "mob-nav"}>
            <li>
                <a href="https://goldfestival.in">Home</a>
                {/* <Link to='/' style={{ fontWeight: 800 }}>Home</Link> */}
            </li>
            <li>
                <a href="https://bgf.goldfestival.in/Participant">Participants</a>
                {/* <Link to='/participant/grid' style={{ fontWeight: 800 }}>Participants</Link> */}
            </li>
            <li>
                <Link to='/winners' style={{ fontWeight: 800 }}>Winner's Corner</Link>
            </li>
            <li>
                <Link to='/past-event' style={{ fontWeight: 800 }}>Past Event</Link>
            </li>
            <li>
                <a href={appConfig.baseUrlImg} style={{ fontWeight: 800 }} target="_blank" rel="noreferrer">Jeweller Login</a>
            </li>
            <li>
                <Link to={'/jeweller-registration'}>
                    <Button type='primary' ghost style={{ fontWeight: 800 }} size='large' className="cus-btn">Jeweller Register</Button>
                </Link>
            </li>
        </ul >
    );

    return (
        <nav style={{
            padding: '10px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: menu ? 'space-between' : 'center',
            alignItems: 'center'
        }}>
            <a href="https://goldfestival.in"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                {data?.logo && <img src={appConfig.baseUrlImg + data?.logo} alt="logo" width={screens.lg ? 80 : screens.md ? 70 : 60} />}
            </a>
            {
                showMenu ? (
                    screens.md ?
                        <Menus />
                        :
                        <MenuUnfoldOutlined style={{ fontSize: 30, color: primaryColor }} onClick={() => setOpen(true)} />
                ) : null
            }

            <Drawer
                placement='left'
                width={'75%'}
                onClose={() => setOpen(false)}
                open={open}
                extra={
                    <Space>
                        {/* <Button onClick={() => setOpen(false)}>Cancel</Button> */}
                        {/* <Button type="primary" onClick={() => setOpen(false)}>OK</Button> */}
                    </Space>
                }
            >
                <Menus cls={true} />
            </Drawer>
        </nav>
    )
}
export default TopNavBar;