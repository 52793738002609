import { Helmet } from 'react-helmet';
import appConfig from "../../app/config/app.config";
import TopNavBar from "../common/TopNavBar";
import { Col, Row, Grid } from 'antd';
import { useLocation } from 'react-router-dom';
const { useBreakpoint } = Grid;

const BaseLayout = ({ page_title, children }) => {
    const screens = useBreakpoint();
    const location = useLocation();
    const showBottomBoxRouterList = [
        '/announcement',
        '/winning',
        '/lossing',
        '/better-luck'
    ];

    return (
        <div style={{ paddingTop: '10px' }}>
            <Helmet>
                <title>{page_title ? page_title : ''} | {appConfig.app.name}</title>
            </Helmet>
            <div style={{ padding: '0 50px' }}>
                <TopNavBar showMenu={!showBottomBoxRouterList.filter((e) => e === location.pathname)} />
            </div>
            <Row
                style={{
                    paddingBottom: '30px', marginTop: screens.md ? 20 : 0
                }}
            >
                {
                    (screens.sm && location.pathname !== '/')
                    &&
                    <Col lg={6} md={6} sm={3} />
                }
                {
                    location.pathname === '/' ?
                        <Col lg={24}>
                            {children}
                        </Col>
                        :
                        <Col lg={12} md={12} sm={18} xs={24}
                            style={{
                                padding: showBottomBoxRouterList.filter((e) => e === location.pathname).length === 0 ? (screens.md ? '30px 0px' : '0') : '0 20px'
                            }}
                        >
                            {children}
                        </Col>
                }
                {
                    (screens.sm && location.pathname !== '/')
                    &&
                    <Col lg={6} md={6} sm={3} />
                }
            </Row>
            {
                showBottomBoxRouterList.filter((e) => e === location.pathname).length > 0
                &&
                <div style={{
                    backgroundColor: '#342929', width: '100%', height: '50px',
                    position: location.pathname === '/lossing' || location.pathname === '/better-luck' ? 'fixed' : '', bottom: 0
                }} />
            }
        </div >
    )
}
export default BaseLayout;