import { combineReducers } from "@reduxjs/toolkit";
import couponReducer from "./slices/couponReducer";
import logoAndSectionReducer from "./slices/logoAndSectionReducer";
import carouselReducer from "./slices/carouselReducer";
import prizeReducer from "./slices/prizeReducer";
import sponsorshipLogoReducer from "./slices/sponsorshipLogoReducer";
import organizerReducer from "./slices/organizerReducer";
import section1Reducer from "./slices/section1Reducer";
import section2Reducer from "./slices/section2Reducer";
import orgSettingsReducer from "./slices/orgSettingsReducer";
import pastEventDetailsReducer from "./slices/pastEventDetailsReducer";
import branchDetailsReducer from "./slices/branchDetailsReducer";
import pastEventListImgsReducer from "./slices/pastEventListImgsReducer";
import pastEventListReducer from "./slices/pastEventListReducer";
import pastEventCarouselImgsReducer from "./slices/pastEventCarouselImgsReducer";
import winnersReducer from "./slices/winnersReducer";

/* SET BLACKLIST */
export const blacklist = [
  "couponReducer",
  "logoAndSectionReducer",
  "carouselReducer",
  "prizeReducer",
  "sponsorshipLogoReducer",
  "organizerReducer",
  "section1Reducer",
  "section2Reducer",
  "orgSettingsReducer",
  "pastEventDetailsReducer",
  "branchDetailsReducer",
  "pastEventListImgsReducer",
  "pastEventListReducer",
  "pastEventCarouselImgsReducer",
  "winnersReducer",
];

/* combine reducers */
export const slices = combineReducers({
  couponReducer,
  logoAndSectionReducer,
  carouselReducer,
  prizeReducer,
  sponsorshipLogoReducer,
  organizerReducer,
  section1Reducer,
  section2Reducer,
  orgSettingsReducer,
  pastEventDetailsReducer,
  branchDetailsReducer,
  pastEventListImgsReducer,
  pastEventListReducer,
  pastEventCarouselImgsReducer,
  winnersReducer,
});
