import { createSlice } from "@reduxjs/toolkit";
import { getLogoAndSectionService } from "../../services/websiteContent.service";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const logoAndSectionSlice = createSlice({
  name: "logoAndSectionReducer",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setLoading, setData, setError } = logoAndSectionSlice.actions;
export default logoAndSectionSlice.reducer;

export const logoAndSectionReduxService = () => async (dispatch, getState) => {
  const state = getState();
  if (state.logoAndSectionReducer.data) {
    // If data exists in Redux, dispatch the existing data and return early
    dispatch(setData(state.logoAndSectionReducer.data));
    return;
  }

  dispatch(setLoading(true));
  try {
    const { data } = await getLogoAndSectionService();
    dispatch(setLoading(false));
    dispatch(setData(data.message));
    dispatch(setError(null));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setData(null));
    dispatch(setError(error));
  }
};
