import { createSlice } from "@reduxjs/toolkit";
import { pastEventDetailService } from "../../services/websiteContent.service";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const pastEventDetailsSlice = createSlice({
  name: "pastEventDetailsReducer",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setLoading, setData, setError } = pastEventDetailsSlice.actions;
export default pastEventDetailsSlice.reducer;

export const pastEventDetailsReduxService =
  (festival) => async (dispatch, getState) => {
    const state = getState();
    if (state.pastEventDetailsReducer.data) {
      // If data exists in Redux, dispatch the existing data and return early
      dispatch(setData(state.pastEventDetailsReducer.data));
      return;
    }

    dispatch(setLoading(true));
    try {
      const { data } = await pastEventDetailService(festival);
      dispatch(setLoading(false));
      dispatch(setData(data.message));
      dispatch(setError(null));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setData(null));
      dispatch(setError(error));
    }
  };
