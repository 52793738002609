import { createSlice } from "@reduxjs/toolkit";
import { CouponService } from "../../services/coupon.service";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const couponSlice = createSlice({
  name: "couponReducer",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setLoading, setData, setError } = couponSlice.actions;
export default couponSlice.reducer;

export const couponReduxService = (transaction_id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const { data } = await CouponService({
      transaction_id,
    });
    dispatch(setLoading(false));
    dispatch(setData(data.message));
    dispatch(setError(null));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setData(null));
    dispatch(setError(error));
  }
};
