import { createSlice } from "@reduxjs/toolkit";
import { getSection1Service } from "../../services/websiteContent.service";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const section1Slice = createSlice({
  name: "section1Reducer",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setLoading, setData, setError } = section1Slice.actions;
export default section1Slice.reducer;

export const section1ReduxService = () => async (dispatch, getState) => {
  const state = getState();
  if (state.section1Reducer.data) {
    // If data exists in Redux, dispatch the existing data and return early
    dispatch(setData(state.section1Reducer.data));
    return;
  }

  dispatch(setLoading(true));
  try {
    const { data } = await getSection1Service();
    dispatch(setLoading(false));
    dispatch(setData(data.message));
    dispatch(setError(null));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setData(null));
    dispatch(setError(error));
  }
};
