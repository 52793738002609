import { Spin } from "antd";
import React from "react";

const PageLoader = ({ message = 'Loading your page...' }) => {

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0, left: 0,
            backgroundColor: 'rgba(255,255,255)',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            <div style={{ width: '250px' }}>
                <Spin tip={message} size="large">
                    <div className="content" />
                </Spin>
            </div>
        </div>
    )
}
export default PageLoader;