import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import redux component
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./app/redux/store";
import { persistStore } from "redux-persist";

import PageLoader from "./components/common/PageLoader";
import BaseLayout from "./components/layout/BaseLayout";
import TermsAndConditions from "./pages/TermsAndConditions";

// import page components
const Home = lazy(() => import("./pages/Home"));
const Participant = lazy(() => import("./pages/Participant"));
const Winners = lazy(() => import("./pages/Winners"));
const PastEvent = lazy(() => import("./pages/PastEvent"));
const PageNotFound = lazy(() => import("./pages/404"));
const Coupon = lazy(() => import("./pages/Coupon"));
const CouponsNotGenerated = lazy(() => import("./pages/CouponsNotGenerated"));
const Announcement = lazy(() => import("./pages/Announcement"));
const Winning = lazy(() => import("./pages/Winning"));
const Lossing = lazy(() => import("./pages/Lossing"));
const BetterLuck = lazy(() => import("./pages/BetterLuck"));
const LuckyDraw = lazy(() => import("./pages/LuckyDraw"));
const JewellerRegistration = lazy(() => import("./pages/JewellerRegistration"));
const VerifyPhone = lazy(() => import("./pages/VerifyPhone"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const RegistrationThankyou = lazy(() => import("./pages/RegistrationThankyou"));

// redux persist
const persistor = persistStore(store);

function App() {
  const routes = [
    {
      path: "/coupon/:transaction_id",
      title: "Coupon Status",
      component: <Coupon />,
    },
    {
      path: "/coupons-not-generated",
      title: "Coupons not generated",
      component: <CouponsNotGenerated />,
    },
    {
      path: "/announcement",
      title: "Announcement",
      component: <Announcement />,
    },
    {
      path: "/winning",
      title: "Winning",
      component: <Winning />,
    },
    {
      path: "/lossing",
      title: "Lossing",
      component: <Lossing />,
    },
    {
      path: "/better-luck",
      title: "Better Luck",
      component: <BetterLuck />,
    },
    {
      path: "/jeweller-registration",
      title: "Jeweller Registration",
      component: <JewellerRegistration />,
    },
    {
      path: "/verify-phone",
      title: "Verify Phone",
      component: <VerifyPhone />,
    },
    {
      path: "/verify-email",
      title: "Verify Email",
      component: <VerifyEmail />,
    },
    {
      path: "/registration-thankyou",
      title: "Registration Thank you",
      component: <RegistrationThankyou />,
    },
  ];

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <BaseLayout page_title={route.title}>
                      {route.component}
                    </BaseLayout>
                  }
                />
              ))}
              <Route path="/" element={<Home />} />
              <Route path="/participant" element={<Participant />} />
              <Route path="/winners" element={<Winners />} />
              <Route path="/past-event" element={<PastEvent />} />
              <Route path="/luck-draw/:winner_id" element={<LuckyDraw />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
