import { createSlice } from "@reduxjs/toolkit";
import { getSponsorshipLogoService } from "../../services/websiteContent.service";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const sponsorshipLogoSlice = createSlice({
  name: "sponsorshipLogoReducer",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

const { setLoading, setData, setError } = sponsorshipLogoSlice.actions;
export default sponsorshipLogoSlice.reducer;

export const sponsorshipLogoReduxService = () => async (dispatch, getState) => {
  const state = getState();
  if (state.sponsorshipLogoReducer.data) {
    // If data exists in Redux, dispatch the existing data and return early
    dispatch(setData(state.sponsorshipLogoReducer.data));
    return;
  }

  dispatch(setLoading(true));
  try {
    const { data } = await getSponsorshipLogoService();
    dispatch(setLoading(false));
    dispatch(setData(data.message));
    dispatch(setError(null));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setData(null));
    dispatch(setError(error));
  }
};
